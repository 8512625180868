<template>
  <div>
    <div v-if="active==0">
      <van-cell-group title="打印机序列号">
        <van-field label="打印机序列号" input-align="right" v-model="spPrintSN" @blur="saveSpPrint" @click-right-icon="scanXpPrint">
          <template #right-icon>
            <van-icon name="scan" color="#1B88EE"></van-icon>
          </template>
        </van-field>
      </van-cell-group>
    </div>
    <van-tabbar v-model="active" @change="handleChange">
      <!-- <van-tabbar-item icon="desktop-o">操作</van-tabbar-item>
      <van-tabbar-item icon="search">查询</van-tabbar-item> -->
      <van-tabbar-item icon="setting-o">设置</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";

Vue.use(Vant);
export default {
  props: ["serialNumber"],
  data() {
    return {
      active: 0,
      spPrintSN: "",
    };
  },
  methods: {
    handleChange() {},
    initSPPrint() {
      let that = this;
      that.axios
        .post("Base_DeviceConfig/GetModel", {
          serialNumber: that.serialNumber,
          configName: "SP序列号",
        })
        .then(function (response) {
          console.log(response);
          that.spPrintSN = response.data.data.configValue;
        });
    },
    scanXpPrint() {
      let that = this;
      that.wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode"],
        success: function (res) {
          that.spPrintSN = res.resultStr.split(",")[0];
          that.saveSpPrint();
        },
      });
    },
    saveSpPrint() {
      let that = this;
      if (!that.spPrintSN) {
        return;
      }
      that.axios
        .post("Base_DeviceConfig/SaveModel", {
          serialNumber: that.serialNumber,
          configName: "SP序列号",
          configValue: that.spPrintSN,
        })
        .then(function (response) {
          if (response.data.code == 101) {
            that.$notify({ message: "保存成功", type: "success" });
          }
        });
    },
  },
  mounted() {
    let that = this;
    //获取绑定的打印机序列号
    that.initSPPrint();
    //获取公众号扫码的凭证
    that.axios
      .post("OfficialAccount/GetWXScanAPI", { onlyText: window.location.href })
      .then(function (response) {
        that.wx = require("weixin-js-sdk");
        that.wx.config({
          debug: false,
          appId: response.data.data.appId,
          timestamp: response.data.data.timestamp,
          nonceStr: response.data.data.nonceStr,
          signature: response.data.data.signature,
          jsApiList: ["scanQRCode"],
        });
        that.wx.ready(function () {});
        that.wx.error(function (err) {
          alert("error" + JSON.stringify(err));
        });
      });
  },
};
</script>

<style>
</style>